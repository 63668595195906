$main--color : #303036;
$second--main-color: #FFCF00;
$blue--main-color: #1c366b;

$red--main: #e84436;
$blue--main: #3BB5F5;
$blue--title: #233667;
$blue--help:#1660C6;
$transparence--bg:#F1F7FF;

$grey--border:#E7E7F2;
$comment-bg:#F0F2F5;
$yellow-bg: #fff9de;
$yellow-content: #FFCF00;
$orange-border: #FF9211;
$red--error:#FA6666;

$turquoise--content: #3FC1C9;
$grey--text: #303030;
$grey--legend:#999999;
$grey--subtitle :#58595A;
$grey--canceled :#DBDEE3;
$orange--mail:#FC5130;
$red--error:#FA6666;


// webApp colors
$color_primary_700: rgba(114,81,17,1);
$color_primary_600: rgba(159,115,29,1);
$color_primary_500: rgba(231,187,0,1);
$color_primary_400: rgba(255,207,0,1);
$color_primary_300: rgba(255,227,107,1);
$color_primary_200: rgba(252,237,175,1);
$color_primary_100: rgba(253,248,232,1);
$color_secondary_700: rgba(6,22,28,1);
$color_secondary_600: rgba(15,37,46,1);
$color_secondary_500: rgba(35,63,83,1);
$color_secondary_400: rgba(54,80,108,1);
$color_secondary_300: rgba(99,118,145,1);
$color_secondary_200: rgba(174,194,218,1);
$color_secondary_100: #F5F9FF;
$color_secondary_50:  #E7EEF7;
$color_success_100: rgba(232,249,251,1);
$color_success_400: rgba(49,194,170,1);
$color_error_700: rgba(160,43,48,1);
$color_error_400: rgb(250, 102, 102);
$color_error_100: rgba(254,244,241,1);
$color_warning_700: rgba(187,91,37,1);
$color_warning_400: rgba(255,146,17,1);
$color_warning_100: rgba(255,242,227,1);
$color_highlight_700: rgba(19,53,78,1);
$color_highlight_400: rgba(68,164,234,1);
$color_highlight_100: rgba(237,246,253,1);
$color_white: rgba(255,255,255,1);
$color_grey_100: rgba(245,249,255,1);
$color_grey_200: rgba(223,229,237,1);
$color_grey_300: rgba(192,200,211,1);
$color_grey_400: rgba(143,149,158,1);
$hub_theme: #31C2AA;
$shopper_theme: #FA6666;
$riders_theme: $color_secondary_400;
$rider--theme_400 : #44A4EA;
$color_ttc: #AEC2DA;

$color_ttc: #AEC2DA;

@mixin text_style_headline_1 {
  font-size:94px;
  font-family:"Manrope";
  font-weight:normal;
  font-style:normal;
}
@mixin text_style_headline_2 {
  font-size:59px;
  font-family:"Manrope";
  font-weight:normal;
  font-style:normal;
}
@mixin text_style_headline_3 {
  font-size:47px;
  font-family:"Manrope";
  font-weight:normal;
  font-style:normal;
}
@mixin text_style_headline_4 {
  font-size:33px;
  font-family:"Manrope";
  font-weight:bold;
  font-style:normal;
}
@mixin text_style_headline_5 {
  font-size:24px;
  font-family:"Manrope";
  font-weight:bold;
  font-style:normal;
}
@mixin text_style_headline_6 {
  font-size:20px;
  font-family:"Manrope";
  font-weight:bold;
  font-style:normal;
}
@mixin text_style_subtitle_1 {
  font-size:1rem;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_subtitle_2 {
  font-size:0.9rem;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_body_1 {
  font-size:16px;
  font-family:"Roboto",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:150%;
}
@mixin text_style_body_2 {
  font-size:14px;
  font-family:"Roboto",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:150%;
}
@mixin text_style_button_big {
  font-size:16px;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_button_medium_small {
  font-size:14px;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin text_style_caption {
  font-size:12px;
  font-family:"Roboto",sans-serif;
  font-weight:normal;
  font-style:normal;
  line-height:150px;
}
@mixin text_style_overline {
  font-size:12px;
  font-family:"Manrope",sans-serif;
  font-weight:bolder;
  font-style:normal;
}
@mixin effect_style_button_elevation {
  box-shadow: 0.00px 4.00px 10.00px rgba(54, 80, 108, 0.150);
}
@mixin transition-hover {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

@mixin transition-hover_quick {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}


@mixin btn--style {
  display: flex;
  padding: 20px 40px;
  color: white;
  background: $blue--main-color;
  border-radius: 5px;
  margin: 20px 5px 0;
  text-decoration: none;
  font-size: 1.35em;
  font-family: Manrope, sans-serif;
  font-weight: 600;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  outline: transparent;
  border: none;
}

@mixin line--under-title {
  position: absolute;
  content: "";
  bottom: -15px;
  left: 0;
  max-width: 60px;
  width: 100%;
  border-radius: 8px;
  height: 7px;
  margin: 0 auto;
  right: 0;
}

@mixin slick--arrow {
  color: transparent;
  background-color: transparent;
  border: none;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  top: 0;
  outline: none;
  &:after {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    display: flex;
    position: absolute;
    top: 40%;
    align-items: center;
    padding: 6px;
    font-size: 2em;
    font-family: 'Material Icons';
  }

}

@mixin slick--prev_after {
  left: -35px;
  content: "\e5c4";
  justify-content: flex-end;
}

@mixin slick--next_after {
  right: -35px;
  content: "\e5c8";
  justify-content: flex-start;
}

@mixin card--style {
  border-radius: 0 0 5px 5px;
  background-color: white;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
}

@mixin tooptilps {
  position: absolute;
  right: -42px;
  background: $transparence--bg;
  color: $blue--help;
  border-radius: 7px;
  border: 1px solid $blue--help;
  padding: 12px;
  bottom: -80px;
  width: 260px;
  font-size: 0.90em;;
  opacity: 0;
  @include transition-hover_quick;
}

@mixin special--input {
  .form--group {
    position: relative;
    width: 100%;
  }
  .has--search .form--control {
    width: 100%;
    display: flex;
    padding: 15px 0 15px 2rem;
    margin: 0 auto;
    border-radius: 2px;
    border: 1px solid $color_secondary_200;
    outline: none;
    caret-color: $color_secondary_200;
    color: $color_secondary_400;

    &::placeholder {
      color: $color_secondary_300;
    }
  }
  .form--control:focus {
    border: 1px solid $color_highlight_400;
    outline: none;
    caret-color: $color_highlight_400;
  }
  .has--search i {
    font-size: 0.8rem;
    position: absolute;
    z-index: 2;
    color: $color_secondary_300;
    font-weight: 300;
    align-items: center;
    display: flex;
    left: 12px;
    height: 100%;
  }
}

@mixin classic--input {
  @include special--input;
  appearance: auto;
  .has--search .form--control {
    padding: 15px;
  }
}

@mixin generalBtn {
  border: none;
  padding: 15px;
  position:relative;
  color: white;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(54, 80, 108, 0.15);
  background: linear-gradient(98.15deg, #233F53 1.58%, #36506C 57.12%);
  background-size: 200% 100%;
  transition: background 0.15s, color 0.25s;
  span{
    color: white;
    position: relative;
    z-index: 2;
  }
  &:before {
    border-radius: 4px;
    top: 0;
    left: 0;
    right: -1px;
    bottom: 0;
    transform-origin: center top;
    transform: scale(1, 0) translateZ(0);
    transition: all 0.25s ease-out;
    content: '';
    display: block;
    position: absolute;
    background-color: $color_primary_400;
  }
  &:hover {
    span {
      color: $color_secondary_400;
    }
    i{
      color:$color_secondary_400;
    }
    &:before { transform: scale(1) translateZ(0); }
  }
}

@mixin backBtn {
  @include generalBtn;
  background: white;
  box-shadow: none;
  border: 1px solid $color_secondary_400;
  color: $color_secondary_400;
  span{
    color: $color_secondary_400;
    z-index: 2;
  }
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform-origin: center top;
    transform: scale(1, 0) translateZ(0);
    transition: all 0.25s ease-out;
    content: '';
    display: block;
    position: absolute;
    border-radius: 4px;
    background-color: $color_primary_200;
  }
  &:hover {
    border-radius: 4px;
    border-color: $color_primary_400;
    color: $color_secondary_400;
    i{
      color:$color_secondary_400;
    }
    &:before { transform: scale(1) translateZ(0); }
  }
}

@mixin cbxStyle {
  .cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;

    span {
      display: inline-block;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);
      font-family: 'manrope', sans-serif;

      &:first-child {
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 3px;
        transform: scale(1);
        vertical-align: middle;
        border: 1px solid $color_secondary_200;
        transition: all 0.2s ease;
        margin-right: 8px;
      }

      &:first-child svg {
        position: absolute;
        top: 3px;
        left: 2px;
        fill: none;
        stroke: $color_secondary_400;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
      }
    }
    span.label--desc{
      font-size: 0.75rem;
      margin-left: 22px;
      line-height: 130%;
      width: 50%;
      margin-top: 5px;
      font-weight: 400;
      color:$color_secondary_300;
    }
    span:first-child:before {
      content: "";
      width: 100%;
      height: 100%;
      background: $color_primary_400;
      display: block;
      transform: scale(0);
      opacity: 1;
      border-radius: 50%;
    }

    span:last-child {
      padding-left: 8px;
    }

    &:hover span:first-child {
      border-color: $color_primary_400;
    }
  }
  .inp-cbx:checked + .cbx span:first-child {
    background: $color_primary_400;
    border-color: $color_primary_400;
    animation: wave 0.4s ease;
    &:before {
      transform: scale(3.5);
      opacity: 0;
      transition: all 0.6s ease;
    }
  }
  .cbx span.color--svg-error{
    border-color: #FA6666;
  }
  .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }

  @keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }
}

@mixin radiobtn {
  .radiobtn {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;

    span {
      display: inline-block;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);

      &:first-child {
        position: relative;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        transform: scale(1);
        vertical-align: middle;
        border: 1px solid $color_secondary_200;
        transition: all 0.2s ease;
      }

      &:first-child svg {
        display: none;
      }
    }

    span:first-child:before {
      content: "";
      width: 100%;
      height: 100%;
      background: $color_primary_400;
      display: block;
      transform: scale(0);
      opacity: 1;
      border-radius: 50%;
    }

    &:hover span:first-child {
      background: white;
      border: 4px solid $color_primary_400;
    }
  }

  .inp-radiobtn:checked + .radiobtn span:first-child {
    background: white;
    border: 3px solid $color_primary_400;
    box-shadow: 0px 1px 4px rgba(255, 207, 0, 0.25), inset 0px 1px 2px rgba(54, 80, 108, 0.1);
    animation: wave 0.4s ease;

    &:before {
      transform: scale(3.5);
      opacity: 0;
      transition: all 0.6s ease;
    }
  }
  .inp-radiobtn:checked + .radiobtn span:first-child svg {
    stroke-dashoffset: 0;
  }


  @keyframes wave {
    50% {
      transform: scale(0.9);
    }
  }
}

@mixin popover {
  font-family: "roboto", sans-serif;
  color: $color_secondary_400;
  padding: 16px;
  .popover-header {
    i {
      color: $color_highlight_400;
      margin-right: 8px;
    }
    border-bottom: none;
    font-weight: bold;
    padding: 0;
    font-size: 1em;
    display: flex;
    color: $color_secondary_400;
    align-items: center;
    background: transparent;
  }
  .popover-body {
    font-size: 0.85em;
    font-weight: 400;
    color: $color_secondary_400;
    padding: 0;
    margin-top: 10px;
  }
}

@mixin typeAheadTwitter() {
  width: 100%;
  .tt-dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
  .tt-menu.tt-open {
    position: absolute;
    top: 40px !important;
    left: 0;
    width: 100%;
    font-family: "roboto", sans-serif;
    font-weight: 700;
    color: $color_secondary_400;
    background: white;
    border-radius: 2px;
    border: 1px solid $color_highlight_400;
    border-top: none;
    display: none;
    overflow: scroll;
    height: 200px;
  }
  .tt-suggestion.tt-selectable {
    cursor: pointer;
    padding: 15px;
    p {
      color: $color_secondary_400;
      font-size: 0.85em;
    }
    &:hover{
      background-color: $color_highlight_100;
      padding: 15px;
    }
  }

  .render--rp_main {
    // margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    i {
      margin-right: 15px;
      position: relative !important;
      left: 0 !important;
    }

  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $color_secondary_200;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
  @-webkit-keyframes #{$name} {
    @content;
  }
}

@import "../../components/utils/headline";
@import '../../../../node_modules/slick-carousel/slick/slick';
@import "../../components/utils/become_choices_modal";

span.bold {
  font-weight: bold;
}

.wrapper--main-illustration {
  .img--main {
    height: 280px;
    background-size: cover;
    width: 100%;
    background-image: url("../../../../public/img/homepage/main-background.jpg");
  }
}

.wrapper--main-text {
  background-color: $color_primary_400;
  padding: 20px;
  top: -5px;
  position: relative;
  min-height: 350px;

  h1 {
    margin-top: 20px;
    font-size: 2.375rem;
    line-height: 130%;
    @include text_style_headline_4;
    color: $color_secondary_400;

    .white--deco {
      color: white;
    }
  }

  p {
    line-height: 150%;
    font-size: 1rem;
    margin-top: 25px;
    font-weight: 500;
    font-family: "manrope";
    color: $color_secondary_400;
  }
}

.wrapper--redirection {
  background: white;
  box-shadow: 0 6px 20px rgba(54, 80, 108, 0.15);
  border-radius: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 24px 24px 40px 24px;
  position: relative;
  width: 90%;
  max-width: 1240px;
  margin: 0 auto 20px auto;

  .redirection--img {
    margin: 0 0 15px 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  h3 {
    @include text_style_headline_h_6;
    font-weight: 500;
    color: $color_secondary_400;
    margin: 0 auto;
  }

  .redirection--text {
    margin: 20px 0;
    font-size: 1rem;
    line-height: 150%;
    color: $color_secondary_400;
  }
}

.wrapper--customer {
  margin-top: -75px;
}

.btn--main {
  width: 100%;
}

.wrapper--why-yper {
  position: relative;
  width: 90%;
  max-width: 1240px;
  padding: 20px;
  margin: 60px auto 100px auto;

  img.deco--path {
    position: absolute;
    opacity: 0.2;
    width: 100%;
    bottom: -10%;
  }

  h2 {
    @include text_style_headline_h_5;
    color: $color_secondary_400;
    margin-bottom: 30px;
  }

  p.main--explanation {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    margin-top: 20px;

  }

  p {
    margin: 15px 0;
    color: $color_secondary_400;
  }
}

.wrapper--youtube_explaination {
  margin: 0;
  border-radius: 5px;
  background-color: $color_secondary_400;
  position: relative;
  overflow: hidden;
  height: 271px;

  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}

.wrapper--quotation {
  display: flex;
  flex-direction: column;
  margin: 40px 0;

  h3 {
    @include text_style_headline_h_5;
    color: $color_secondary_400;
    padding: 15px 30px;
    text-align: center;

    .bold {
      font-weight: 700;
    }
  }

  h4 {
    @include text_style_headline_h_7;
    background-color: $color_primary_400;
    color: $color_secondary_400;
    padding: 6px;
    text-align: center;
    margin: 0 auto;
    width: fit-content;
  }
}

.wrapper--mockup_home {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;

  .yper--notification {
    background: rgba(255, 255, 255, 0.7);
    mix-blend-mode: normal;
    box-shadow: 3px 8px 20px rgba(54, 80, 108, 0.05);
    backdrop-filter: blur(15px);
    border-radius: 10px;
    width: 320px;
    padding: 20px;
    position: relative;
    z-index: 1;
    top: 30px;
    left: 11px;

    .notification--header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .notification--logo {
        width: fit-content;
        display: flex;
        align-items: center;
        color: $color_secondary_300;
        font-size: 0.80rem;
        text-transform: uppercase;
        font-weight: bold;

        img {
          margin-right: 5px;
          border-radius: 5px;
          background-color: $color_primary_400;
          padding: 5px;
          width: 20px;
        }
      }

      .notification--time {
        color: $color_secondary_300;
        font-size: 0.80rem;
      }
    }

    .notification--content {
      @include text_style_subtitle_sub_1;
      color: $color_secondary_400;

      h5 {
        margin: 15px 0 5px 0;
      }

      p {
        font-size: 0.9rem;
      }
    }
  }

  .wrapper--mock_alignement {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: relative;
    height: 100%;
    align-items: flex-end;
    min-height: 240px;

    img.mockup--mac {
      height: 216px;
      right: -38%;
      bottom: 23px;
      width: auto;
      position: absolute;
    }

    img.mockup--device {
      height: 210px;
      width: auto;
      bottom: 0;
      position: absolute;
      left: 0;
    }
  }
}

.wrapper--quotation_content {
  width: 100%;
  max-width: 1240px;
  margin: 81px auto;
}

.wrapper--sav {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 20px;

  .wrapper--sav_title {
    display: flex;
    margin-bottom: 15px;
    flex-direction: row;

    h4 {
      margin-left: 10px;
      @include text_style_headline_h_7;
      color: $color_secondary_400;
      text-align: left;
    }
  }

  p {
    margin-left: 25px;
    color: $color_secondary_300;
  }
}

.wrapper--img {
  width: 100%;
  height: 270px;
  position: relative;
  margin-bottom: 100px;

  img {
    position: absolute;
  }

  img.img--one {
    position: relative;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.wrapper--bubble {
  img {
    position: relative;
    height: auto;
    width: 100%;
  }
}

.wrapper--advantages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.wrapper--picto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .wrapper--text {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: "manrope", sans-serif;
    color: $color_secondary_400;
    margin: 10px 0;
  }
}

.picto--illu {
  img {
    width: 80px;
  }
}

.picto--img {
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(54, 80, 108, 0.15);
  width: 90px;
  height: 90px;
  border-radius: 50%;
  position: relative;
  padding: 25px;

  &:after {
    width: 0;
    height: 0;
    content: '';
    z-index: 2;
    border-top: 1.5em solid white;
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    position: absolute;
    left: 37%;
    border-radius: 3px;
    bottom: -10px;
  }
}

.wrapper--slider_content {
  a:hover {
    text-decoration: none;
  }

  .slick-slide {
    margin: 0 0 0 8px !important;
  }

  width: 100%;
  margin: 70px 0;
  background: $color_primary_100;
  position: relative;
}

.wrapper--slider {
  width: 100%;
  margin: 40px 0;
  position: relative;
  top: -51px;

  .item--slider {
    position: relative;
    background: white;
    border-radius: 20px;
    overflow: hidden;

    img {
      height: 172px;
      width: 100%;
      object-fit: cover;
      object-position: top;

    }
  }

  .slider--text {
    padding: 20px;
    min-height: 230px;

    h6 {
      @include text_style_headline_h_6;
      color: $color_secondary_400;
    }

    p {
      margin-top: 20px;
      color: $color_secondary_400;
    }
  }

  .slick-list {
    padding: 0 30% 0 0 !important;
  }
}

.slider--btn {
  border-top: 2px solid #E7EEF7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 1rem;
  font-weight: 700;
  color: #44A4EA;
  font-family: "manrope", sans-serif;
}

.delivery--details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 40px;
  padding: 20px;
  height: 115px;
  width: 100%;
  background-color: $color_primary_100;
  border-bottom: 25px solid $color_primary_200;

  .picto--img {
    width: 80px;
    height: 80px;
    top: -8px;

    img {
      width: 35px;
      height: auto;
    }
  }
}

.wrapper--delivery {
  .wrapper--text {
    padding: 20px;
    margin: 20px 0;

    h4 {
      margin: 20px 0;
      @include text_style_headline_h_6;
      color: $color_secondary_400;
    }

    p {
      color: $color_secondary_400;

      .bold {
        font-weight: 700;
      }
    }
  }

  .building--deco {
    display: none;
  }
}

.wrapper--map {
  z-index: 10;
  position: relative;
  overflow: hidden;
  background: $color_secondary_400;

  .continent--map {
    position: absolute;
    right: 0;
  }

  .map--content {
    max-width: 1240px;
    width: 90%;
    margin: 0 auto;
  }

  .wrapper--text-explanation {
    padding: 20px;
  }

  .headline--title {
    margin-bottom: 30px;
    color: white;
    @include text_style_headline_h_5;
  }

  .img--world {
    position: relative;
    padding: 0;

    img {
      position: absolute
    }

    img.france {
      width: auto;
      display: flex;
      left: 0;
      right: 0;
      background: $color_secondary_400;
      margin: 5px auto;
      position: relative;
    }

    img.ue {
      right: -10%;
      top: -36px;
    }

    img.corse {
      bottom: -16px;
      right: 5%;
    }

    img.spain {
      left: -5%;
      bottom: -15px;
    }
  }

  p {
    padding: 20px;
    margin: 20px 0;
    color: white;
  }

  .button--space_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 10;
    position: relative;

    .button--space {
      width: 88px;
      height: 72px;
      cursor: pointer;
      background: white;
      box-shadow: 0px 7px 10px #213A54;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      justify-content: center;
      border: 3px solid transparent;
      @include transition-hover_quick;

      img {
        margin-bottom: 5px;
      }

      h5 {
        color: $color_secondary_400;
        @include text_style_headline_h_8;
      }
    }

    .button--space {
      border: 0;
      box-sizing: border-box;
      margin: 1em;
      height: 72px;
      cursor: pointer;
      background: white;
      box-shadow: 0px 7px 10px #213A54;
      padding: 20px;
      font-size: inherit;
      font-weight: 700;
      position: relative;
      vertical-align: middle;

      &::before,
      &::after {
        box-sizing: inherit;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 16px;
      }

      &::before,
      &::after {
        content: "";
        border-radius: 16px;
        border: 3px solid transparent;
        width: 0;
        height: 0;
      }

      &::before {
        top: 0;
        left: 0;
      }

      &::after {
        bottom: 0;
        right: 0;
      }
    }

    .shopper--space.active {
      color: $shopper_theme;
      visibility: hidden;
    }

    // Hover styles
    .button--space.active::before,
    .button--space.active::after {
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }

    .button--space.active::before {
      transition: width 0.25s ease-out, // Width expands first
        height 0.25s ease-out 0.25s; // And then height
    }

    .button--space.active::after {
      transition: border-color 0s ease-out 0.25s
    }

    .shopper--space.active::before {
      border-top-color: $shopper_theme; // Make borders visible
      border-right-color: $shopper_theme;
    }

    .shopper--space.active::after {
      border-bottom-color: $shopper_theme; // Make borders visible
      border-left-color: $shopper_theme;
      transition: border-color 0s ease-out 0.5s
    }

    .riders--space.active::before {
      border-top-color: $rider--theme_400; // Make borders visible
      border-right-color: $rider--theme_400;
    }

    .riders--space.active::after {
      border-bottom-color: $rider--theme_400; // Make borders visible
      border-left-color: $rider--theme_400;
      transition: border-color 0s ease-out 0.5s
    }

    .hubs--space.active::before {
      border-top-color: $hub_theme; // Make borders visible
      border-right-color: $hub_theme;
    }

    .hubs--space.active::after {
      border-bottom-color: $hub_theme; // Make borders visible
      border-left-color: $hub_theme;
      transition: border-color 0s ease-out 0.5s
    }

    .riders--space:hover {
      border-color: $riders_theme;
    }

    .hubs--space:hover {
      border-color: $hub_theme;
    }
  }
}

.wrapper--partners {
  width: 90%;
  max-width: 1240px;
  margin: 104px auto;

  h2 {
    margin-bottom: 32px;
    @include text_style_headline_h_5;
    color: $color_secondary_400;
    text-align: center;
  }
}

.partners--content-wrapper {
  display: flex;
  flex-direction: column;

  .partners--content {
    background: white;
    padding: 20px;
    min-height: 142px;
    border-radius: 6px;
    filter: drop-shadow(0px 4px 5px rgba(143, 149, 158, 0.3));
    margin: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .logo--partner {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 25px;
    }

    .partner--description {
      display: flex;
      flex-direction: column;

      p {
        color: $color_secondary_400;
        font-size: 1.1rem;
        font-weight: 500;
        font-family: "manrope", sans-serif;
      }

      a {
        color: $color_highlight_400;
        display: flex;
        align-items: center;
        font-size: 1rem;
        margin: 0 auto;

        i {
          @include transition-hover_quick;
          position: relative;
          left: 0;
        }

        &:hover {
          text-decoration: none;

          i {
            left: 5px;
          }
        }
      }
    }
  }
}

.slider--arrow {
  display: none;
}

@media screen and (max-width: 620px) {
  .wrapper--picto {
    margin-bottom: 24px;
  }

  .slider--arrow {
    display: none;
  }
}

@media screen and (min-width: 670px) {
  .wrapper--partners {
    .partners--content-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .partners--content {
        width: 47%;
        flex-direction: row;
      }
    }
  }
}

@media screen and (min-width: 620px) {
  .slider--arrow {
    left: 0;
    display: flex;
    z-index: 0;
    flex-direction: row;
    position: absolute;
    z-index: 3;
    width: 94%;
    bottom: 40%;
    text-align: center;
    right: 0;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }

  .arrow--back {
    margin-left: auto;
  }

  .arrow--forward,
  .arrow--back {
    border-radius: 50%;
    background: #FFFFFF;
    border: 1px solid rgba($color_secondary_400, 0.3);
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin: 0 5px;
    @include transition-hover_quick;
    color: $color_secondary_200;

    &:hover {
      border: 1px solid $color_secondary_400;
      color: $color_secondary_400;
    }
  }
}

@media screen and (min-width: 700px) {
  .wrapper--header {
    height: 125vh;
    margin-top: 60px;
  }

  .wrapper--main-illustration {
    position: relative;
    height: 90vh;
    width: 100%;
    top: -61px;
    z-index: -1;

    .img--main {
      height: 100%;
      border-radius: 0px 0px 150px 150px;
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
      background-image: url("../../../../public/img/homepage/main-background.jpg");
    }

    .wrapper--main-text {
      margin: 0 auto;
      position: absolute;
      top: -17%;
      left: 0;
      bottom: 0;
      right: 0;
      max-width: 1240px;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        @include text_style_headline_1;
        color: white;
        font-weight: 500;
        width: 50%;
        margin-left: auto;

        .white--deco {
          color: $color_primary_400;
        }
      }

      p {
        width: 50%;
        margin-left: auto;
        color: white;
        font-size: 1.25rem;
      }
    }
  }

  .wrapper--redirection_content {
    width: 90%;
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: -150px;
    position: relative;

    .redirection--description {
      min-height: 275px;
    }

    .wrapper--redirection {
      width: 32.3%;

      .redirection--img {
        justify-content: flex-start;
      }
    }

    .wrapper--customer {
      margin-top: 0;
    }
  }

  .wrapper--quotation {
    h3 {
      font-size: 2.938rem;
      line-height: 130%;
    }

    h4 {
      cursor: default;
      font-size: 2.063rem;
    }
  }

  .wrapper--quotation_content {
    margin: 50px auto;
    width: 90%;
    max-width: 1240px;
    position: relative;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;

    .wrapper--img {
      height: 353px;
    }

    .wrapper--mockup_home {
      width: 50%;
    }

    .wrapper--bubble {
      width: 50%;

      img {
        transform: translateY(-100px);
        opacity: 0;
        width: 100%;
      }
    }

    .wrapper--mock_alignement img.mockup--device {
      height: 250px;
      bottom: -32px;
      position: absolute;

    }

    .wrapper--mock_alignement img.mockup--mac {
      height: 216px;
      right: -38%;
      bottom: 23px;
      width: auto;
      position: absolute;
    }

    .logistic--img {
      margin-bottom: 0;
      width: 50%;

      img {
        position: relative;
        width: auto;
        height: 100%;
        left: -30%;
      }
    }

    .wrapper--sav {
      width: 45%;

      h4 {
        @include text_style_headline_4;
        font-weight: 400;
      }
    }
  }

  .wrapper--advantages {
    flex-direction: row;
    width: 90%;
    max-width: 1240px;
    margin: 0 auto;

    .wrapper--picto {
      width: 33%;
    }
  }

  .wrapper--delivery_content {
    margin-top: -70px;
    background: $color_primary_100;

    .wrapper--delivery {
      background: $color_primary_100;
    }
  }

  .delivery--details {
    height: auto;
    border: none;
    background-color: transparent;

    .picto--img {
      width: 110px;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 54px;
      }

      &:after {
        border-left: 2em solid transparent;
        border-right: 2em solid transparent;
        left: 27%;
        bottom: -12px;
      }
    }
  }

  .wrapper--delivery {
    position: relative;
    z-index: 3;

    img.building--deco {
      display: flex;
      position: absolute;
      bottom: -25px;
      z-index: -1;
      width: 100%;
    }
  }

  .wrapper--slide_slick {
    display: flex !important;
    flex-direction: column-reverse;

    .wrapper--text {
      text-align: center;
      width: 450px;
      height: 295px;
      margin: 0 auto;

      a.see--more {
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        z-index: 10;
        padding: 12px 15px;
      }
    }
  }

  .wrapper--map {
    height: 100vh;
    display: flex;

    .map--content {
      display: flex;
      flex-direction: row;
      align-items: center;

      .wrapper--text-explanation,
      .wrapper--map-explanation {
        width: 49%;
      }
    }
  }

  .partners--content-wrapper .partners--content .partner--description a {
    margin: inherit;
  }
}

@media screen and (min-width: 800px) {
  .wrapper--why-yper {
    width: 90%;
    max-width: 1240px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .yper--title {
      display: flex;
      justify-content: center;
      width: 40%;
      flex-direction: column;
    }

    .wrapper--youtube_explaination {
      position: relative;

      .mask--animation,
      .mask--animation2 {
        background-color: $color_primary_400;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
      }

      .mask--animation2 {
        z-index: 0;
        background-color: $color_secondary_400;
      }

      width: 53%;
      margin: 0;
      height: 400px;
    }
  }

  .wrapper--map .button--space_content {
    .button--space {
      display: flex;
      flex-direction: row;
      width: auto;
      height: inherit;
      align-items: center;

      img {
        margin-bottom: 0;
        margin-right: 5px;
      }
    }
  }
}

@media screen and (min-width: 920px) {
  .wrapper--partners .partners--content-wrapper .partners--content {
    width: 31.3%;
  }

  .wrapper--redirection {
    h3 {
      font-size: 1.62rem;
    }
  }

  .wrapper--why-yper {
    h2 {
      font-size: 2.9rem;
    }
  }

  .wrapper--quotation {
    h3 {
      font-size: 2.9rem;
      line-height: 130%;
    }

    h4 {
      font-size: 2rem;
    }
  }

  .wrapper--header {
    height: 135vh;
  }

  .yper--notification {
    transform: translateY(-100px);
    opacity: 0;
  }

  .wrapper--quotation_content .wrapper--mock_alignement {
    img.mockup--mac {
      height: 267px;
      right: -28%;
      opacity: 0;
      bottom: 0;
      transform: translateY(-100px);
    }

    img.mockup--device {
      height: 250px;
      opacity: 0;
      bottom: -32px;
      transform: translateY(-100px);
    }
  }

  .wrapper--slide_slick {
    .wrapper--text {
      height: 200px;
    }
  }

  .quotation--advantages {
    h3 {
      font-size: 2.3rem;
    }
  }

  .wrapper--slide .wrapper--text {
    h4 {
      font-size: 2.3rem;
    }
  }

  .wrapper--map .headline--title {
    font-size: 2.9rem;
    line-height: 130%;
  }

  .wrapper--map .button--space_content {
    justify-content: flex-start;

    .button--space {
      margin-right: 10px;
    }
  }

  .wrapper--partners h2 {
    font-size: 2.3rem;
  }
}

@media screen and (min-width: 1100px) {
  .wrapper--quotation_content .logistic--img {
    img {
      height: auto;
      left: -40%;
      top: -10%;
    }
  }
}

@media screen and (min-width: 1200px) {
  .wrapper--slider_content {
    .wrapper--slider {
      width: 90%;
      margin: 0 0 0 auto;
    }
  }

  .wrapper--quotation_content .wrapper--mock_alignement img.mockup--mac {
    right: 0;
  }
}

@media screen and (min-width: 1850px) {
  .wrapper--slider_content {
    .wrapper--slider {
      width: 100%
    }
  }

  .wrapper--quotation_content .logistic--img {
    height: 500px;
    margin-bottom: 0;

    img {
      height: 100%;
      left: -55%;
    }
  }
}