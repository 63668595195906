.become--choices_modal.show {
  .modal-dialog .modal-content {
    .desktop--behavior{
      display:none;
    }
    padding: 0!important;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(15, 37, 46, 0.5);
    border-radius: 30px!important;
    border: none;
    .modal-header{
      display: flex;
      align-items: center;
      padding: 20px;
      border-radius: 30px 30px 0 0;
      background-color: $color_primary_400;
      button i{
        color:$color_secondary_400;
      }
      h5{
        font-family:"manrope",sans-serif;
        font-size: 1.5rem;
        color: $color_secondary_400;
        font-weight: 400;
      }
    }
    .become--windows_content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 20px auto;
      width:100%;
      .become--windows{
        width: 100%;
        padding: 40px 0;
        color:$color_secondary_400;
        text-align: center;
        p{
          font-family: "Manrope",sans-serif;
        }
        .picto--connexion{
          img{
            width: 50px;
          }
        }
        .text--connexion{
          font-family:"manrope",sans-serif;
          margin: 20px 0;
        }
        &:hover{
          text-decoration: none;
        }
      }
      .become--windows:nth-child(2){
        border-top:1px solid $color_secondary_200;
      }
    }
    .responsive--behavior{
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (min-width:620px) {
  .become--choices_modal.show {
    .modal-dialog {
      max-width: 700px;
      .modal-content {
        .responsive--behavior {
          display: none;
        }

        .desktop--behavior {
          display: flex;
          flex-direction: column;
        }

        .title--header {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          text-align: center;
          flex-direction: column;
          padding: 5px 20px;

          h5 {
            font-size: 2.375rem;
            font-weight: 500;
            font-family: "manrope", sans-serif;
            margin-bottom: 15px;
          }

          p {
            font-family: "Manrope", sans-serif;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.15px;
            color: $color_secondary_400;
          }
        }

        .desktop--behavior {
          display: flex;
        }

        .become--windows_content {
          flex-direction: row;
          justify-content: space-between;

          p {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
  .become--choices_modal.show .modal-dialog .modal-content .become--windows_content .become--windows:nth-child(2){
    border-top: none;
    border-left: 1px solid $color_secondary_200;
  }
}